/* eslint-disable no-console */
import React from 'react';
import config from './config';
import URI from 'urijs';
import  { nanoid }  from 'nanoid';
import constants from './constants';
import { intersection } from 'lodash/array';
import {
  getOAuthState,
  setAndGetLwaUniqId,
  setLoginVerificationValues,
  getUseWorkflowHandlerCookie
} from './cookie-utils';
import { logReloginEvent } from './amplitude-utils';
import sessionStorageUtil from './session-storage-util';
import { fetchLogoProps } from './logo-utils';
import { ValidationError } from './errors';


export const parseAudForMetadata = (aud) => {
  try {
    let practiceId;
    let brandId;
    const match = aud.match(/(\d+)\/(\d+)\/\d+\/fhir\/dstu2$/) ||
                  aud.match(/(\d+)\/brand\/(\d+)\/csg\/(\d+)\/fhir\/r4/) ||
                  aud.match(/(\d+)\/(\d+)\/fhir\/r4/);
    if (match) {
      [ , practiceId, brandId ] = match;
    } else {
      return { };
    }
    const metadata = {};
    if (practiceId) {
      metadata.practiceId = practiceId;
    }
    // brand is set to 0 for athenaNet practices that don't have any brands
    // Ignore brand if it's not set or is a falsey-value.
    if (brandId && parseInt(brandId) !== 0) {
      metadata.brandId = brandId;
    }
    return metadata;
  } catch (e) {
    return { };
  }
};

export const parsedPayload = (searchParams) => (React.useMemo(() => {
  const scopeString = searchParams.get('scope');
  const scopesArray = (scopeString && scopeString.split(/\s+/g)) || [];
  const redirectUrl = searchParams.get('redirect_uri');
  const clientId = searchParams.get('client_id');
  const state = searchParams.get('state');
  const aud = searchParams.get('aud');
  const responseType = searchParams.get('response_type');
  const prompt = searchParams.get('prompt');
  const createAccount = searchParams.has('create');
  const launch = searchParams.get('launch');
  const idp = searchParams.get('idp');
  const sessionToken = searchParams.get('sessionToken');
  const relogin = searchParams.get('relogin');
  const testApp = searchParams.get('testApp');
  const username = searchParams.get('login_hint');
  const uiLocales = searchParams.get('ui_locales');
  const userId = searchParams.get('amplitude_user_id');
  const amplitudeDeviceId = searchParams.get('amplitude_device_id');

  let emailToken;
  if (searchParams.get('registration_token')) {
    emailToken = searchParams.get('registration_token');
  } else if (searchParams.get('email_token')) {
    emailToken = searchParams.get('email_token');
  } else if (searchParams.get('emailToken')) {
    emailToken = searchParams.get('emailToken');
  } else if (searchParams.get('registrationToken')) {
    emailToken = searchParams.get('registrationToken');
  }

  let skipPatientSummary = false;
  const skipPatientSummaryValue = searchParams.get('skip_patient_summary') || searchParams.get('skipPatientSummary');
  if (skipPatientSummaryValue !== null) {
    skipPatientSummary = skipPatientSummaryValue === 'true';
  }

  let hideRememberMe = false;
  const hideRememberMeValue = searchParams.get('hide_remember_me') || searchParams.get('hideRememberMe');
  if (hideRememberMeValue !== null) {
    hideRememberMe = hideRememberMeValue === 'true';
  }

  let portalSettings = {};
  try {
    const parsedState = JSON.parse(state);
    const { PORTALREDIRECTURL, LANGUAGE } = parsedState;
    portalSettings = { PORTALREDIRECTURL, LANGUAGE };
  } catch (err) { }
  const fhir = isFhirScopeRequested(scopesArray);
  function isFhirScopeRequested(scopes) {
    return intersection(config.fhirScopes, scopes).length > 0;
  }

  function isFhirEhrLaunchRequested(scopes) {
    return intersection(config.fhirEhrLaunchScopes, scopes).length > 0;
  }
  const isFhirEhrLaunch = isFhirEhrLaunchRequested(scopesArray) && !!launch;
  const authorizeRedirectUrl = new URI(window.location.href).origin(config.oktaURL);
  const originalRedirectUri = new URI(window.location.href).removeQuery('prompt', 'login');
  const authServerId = authorizeRedirectUrl.segment()[1];
  const lwaUniqId = setAndGetLwaUniqId();

  const customData = {};
  customData.useWorkflowHandlerCookie = getUseWorkflowHandlerCookie();

  return {
    clientId: clientId,
    scopesArray,
    scopeString,
    redirectUri: redirectUrl,
    aud,
    prompt,
    emailToken,
    launch,
    portalSettings,
    createAccount,
    isFhirEhrLaunch,
    fhir,
    idp,
    authorizeRedirectUrl,
    sessionToken,
    relogin,
    testApp,
    authServerId,
    responseType,
    username,
    state,
    lwaUniqId,
    userId,
    amplitudeDeviceId,
    originalRedirectUri,
    uiLocales,
    skipPatientSummary,
    customData,
    hideRememberMe,
  };
}, [ searchParams ]));


export function enrichApiResponse({ apiResponse, parsedPayload }) {
  const { authorizeRedirectUrl, fhir, sessionToken, testApp, authServerId, createAccount,
    clientId, aud, state, username, originalRedirectUri, skipPatientSummary } = parsedPayload;
  const idp = apiResponse.idp || parsedPayload.idp;
  const usernameResponse = apiResponse.username;
  let workflow;
  if (idp) {
    authorizeRedirectUrl.removeQuery('prompt', 'login').addQuery({ idp });
    workflow = constants.AUTHN_WORKFLOWS.AUTHORIZEVIAIDP;
  } else {
    // for non-federated cases, we provide our own login experience, so we override some parameters related to that,
    // but for the federated case we want to let Okta handle login prompting behavior
    authorizeRedirectUrl.removeQuery('prompt', 'login').removeQuery('login_hint');
    if (fhir) {
      workflow = constants.AUTHN_WORKFLOWS.FHIRAUTHORIZE;
    } else {
      workflow = constants.AUTHN_WORKFLOWS.AUTHORIZE;
    }
  }
  const postAuthnRoutes = JSON.stringify(apiResponse.postProcessingSteps.map((step) => {
    if (step.needsSanitize) {
      return { route: 'account/sanitize' };
    }
    if (step.workflow) {
      return { route: 'workflow' };
    }
    if (step.authorizeToOkta) {
      return { url: authorizeRedirectUrl.toString() };
    }
  }).filter(( ele ) => !!ele ));
  const prompt = idp ? undefined : parsedPayload.prompt;
  const proxiedAuthorizeValues = { workflow, originalRedirectUri: originalRedirectUri.toString(), fhir, sessionToken,
    testApp, authServerId, idp, prompt: parsedPayload.prompt, skipPatientSummary,
    // eslint-disable-next-line camelcase
    profile: false, login_hint: usernameResponse };
  const portalSettings = JSON.stringify(parseAudForMetadata(aud));
  return {
    ...apiResponse,
    idp,
    postAuthnRoutes,
    proxiedAuthorizeValues: JSON.stringify(proxiedAuthorizeValues),
    createAccount,
    clientId,
    aud,
    state,
    prompt,
    username: apiResponse.username || username,
    workflow,
    portalSettings,
    ...fetchLogoProps(idp),
  };


}

/**
 * Check if relogin attempted already, attempts if not
 * @param cause {string} - Reason for performing the relogin, so it gets logged to amplitude
 * @return {Promise<boolean>} Indicates if the relogin is being performed
 */
export async function performReloginIfAllowed({ cause, message }){
  const originalAuthUrl = sessionStorageUtil.getOriginalAuthUrl();
  if (originalAuthUrl) {
    const parsedUrl = new URL(originalAuthUrl);
    if (!parsedUrl.searchParams.get('relogin')) {
      parsedUrl.searchParams.append('relogin', '1');
      logReloginEvent({ cause, message });
      window.location.replace(parsedUrl.toString());
      return true;
    }
  }
  return false;
}

/**
 * Get username from session if exist
 * @param {authClient} OktaAuth from @okta/okta-react
 * @returns {Promise<undefined|string>}
 */
export async function getUsernameFromSession(oktaAuth) {
  const { login } = await oktaAuth.session.get();
  return login;
}


export function showPrimaryAuth() {
  const currentHash = window.location.hash.replace(/^#/, '');
  // Some pages shouldn't go to the login page.
  const noLogins = [
    'create',
    'profile',
    'signin/athena-forgot-password',
  ];
  document.getElementById(constants.LOGIN_CONTAINER_ID).style.display = 'block';
  if (noLogins.indexOf(currentHash) >= 0 ) {
    return;
  }
  if (currentHash !== '') {
    window.location.hash = '';
  }
}

export const validateState = ({ parsedState }) => {
  const stateCookie = getOAuthState();
  if (!stateCookie) {
    console.warn('No cookies present in request');
    throw new ValidationError('Unauthorized');
  }
  if (stateCookie !== parsedState.uniqueKey) {
    console.warn(`${constants.ATHENA_OAUTH_STATE_COOKIE} cookie doesn't match state value` + `(${stateCookie} vs ${parsedState.uniqueKey})`);
    return false;
  }
  return true;
};


export const getAuthorizeToOktaValues = ({ proxiedAuthorizeValues }) => {

  // https://tools.ietf.org/html/rfc6819#section-3.6
  const oauthState = nanoid();
  const oauthNonce = nanoid();

  const values = Object.assign({}, proxiedAuthorizeValues);
  const loginHint = values.login_hint;

  values.uniqueKey = oauthState;
  const sessionToken = values.sessionToken;
  delete values.profile;
  delete values.sessionToken;
  const { idp, prompt } = values;
  let finalPrompt;
  if (idp) {
    // with IDP we don't want to prompt for consent for the myidentity-webapp
    if (prompt === 'consent') {
      finalPrompt = '';
    } else {
      finalPrompt = prompt;
    }
  } else {
    // We just authenticated the user by forcing showPrimaryAuth for non-idp
    finalPrompt = 'none';
  }

  const authorizeParams = {
    response_type: 'code',
    client_id: config.oauthClientId,
    prompt: finalPrompt,
    state: JSON.stringify(values),
    nonce: oauthNonce,
    idp,
    sessionToken,
    loginHint,
  };
  return {
    authorizeParams,
    cookies: {
      oauthNonce,
      oauthState,
    },
  };
};

export const authorizeToOkta = (proxiedAuthorizeValues, oktaAuth) => {
  const values = getAuthorizeToOktaValues({
    proxiedAuthorizeValues,
  });
  setLoginVerificationValues({ state: values.cookies.oauthState, nonce: values.cookies.oauthNonce });
  const {
    idp,
    prompt,
    sessionToken,
    state,
    loginHint,
  } = values.authorizeParams;
  //Need to store this state since this gets cleared out by oka-oauth-js
  sessionStorageUtil.setLoginState(state);
  return oktaAuth.signInWithRedirect({
    idp,
    prompt,
    state,
    sessionToken,
    originalUri: window.location.href,
    loginHint,
  });
};
