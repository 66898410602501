import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Loader from '../Loader';
import { initManage } from '../lib/scripts/page-specific/profile/manage';
import { useQuery } from '../lib/router-utils';
import constants from '../lib/constants';
import PreAuthorize from './PreAuthorize';
import PostAuthorize from './PostAuthorize';
import { logSpaLandingEvent } from '../lib/amplitude-utils';
import config from '../lib/config';
import { fetchLogoProps } from '../lib/logo-utils';

export default function ManageProfile() {
  const query = useQuery();
  const language = query.get('ui_locales');
  const clientId = query.get('client_id');
  const { authState } = useOktaAuth();
  const oktaOrgId = config.oktaOrgId;

  const workflow = constants.AUTHN_WORKFLOWS.MANAGEPROFILE;

  const defaultProps = {
    proxiedAuthorizeValues: JSON.stringify({ originalRedirectUri: window.location.href, workflow, profile: true }),
    postAuthnRoutes: JSON.stringify([ { route: 'profile' } ]),
    workflow,
    renderWidgetFunction: initManage,
    language,
    clientId,
    oktaOrgId,
    ...fetchLogoProps(),
  };

  React.useEffect(() => {
    document.title = 'Profile';
  });

  React.useEffect(() => {
    logSpaLandingEvent();
  }, []);


  if (!authState) return <Loader />;
  if (authState.isAuthenticated) {
    return (<PostAuthorize {...defaultProps} />);
  } else {
    return (<PreAuthorize {...defaultProps} />);
  }
}
