export class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ValidationError';
  }
}

export class RedirectableError extends Error {
  constructor(message, redirectUrl) {
    super(message);
    this.name = 'RedirectableError';
    this.redirectUrl = redirectUrl;
  }
}

export class DegradedStateAttemptedError extends Error {
  constructor(error) {
    super(error);
    this.name = 'DegradedStateAttemptedError';
  }
}

export class DegradedStateFailureError extends Error {
  constructor(error) {
    super(error);
    this.name = 'DegradedStateFailureError';
  }
}
