/* eslint-disable no-console */
/* eslint-disable camelcase */
/* global OktaSignIn */
import { loadAndSyncSession } from '../scripts/login-frame';
import { transformUsername, isUserMismatchError } from '../../lib/user-utils';
import { showPrimaryAuth, authorizeToOkta } from '../../lib/authorize-utils';
import { formatIdToken, formatAccessToken, getDeviceTimeOffset, treatAsJSON } from '../../lib/signin-widget-utils';
import iamCoreClient from '../iam-core-client';
import constants from '../constants';
import { logger } from "../logger";
const LOGIN_CONTAINER_SELECTOR = '#okta-login-container';
const DEVICE_TIME = Date.now();

export function getLoginContainerData() {
  const loginContainer = document.getElementById(constants.LOGIN_CONTAINER_ID);

  const containerData = {
    oktaUrl: loginContainer.getAttribute('data-okta-url'),
    consentOktaUrl: loginContainer.getAttribute('data-consent-okta-url'),
    athenanet: treatAsJSON(loginContainer.getAttribute('data-athenanet')),
    authorizeRedirectUrl: loginContainer.getAttribute('data-authorize-redirect-url'),
    proxiedAuthorizeUrl: loginContainer.getAttribute('data-proxied-authorize-url'),
    proxiedAuthorizeValues: treatAsJSON(loginContainer.getAttribute('data-proxied-authorize-values')),
    forgotPasswordAthena: loginContainer.getAttribute('data-forgot-password-athena'),
    supportEnv: loginContainer.getAttribute('data-support-env'),
    anetUserDomain: loginContainer.getAttribute('data-anet-user-domain'),
    createAccount: treatAsJSON(loginContainer.getAttribute('data-create-account')),
    prompt: loginContainer.getAttribute('data-prompt'),
    username: loginContainer.getAttribute('data-username'),
    sessionToken: loginContainer.getAttribute('data-session-token'),
    stateToken: loginContainer.getAttribute('data-state-token'),
    applicationId: loginContainer.getAttribute('data-application-id'),
    redirectUri: loginContainer.getAttribute('data-redirect-uri'),
    accessToken: loginContainer.getAttribute('data-access-token'),
    idToken: loginContainer.getAttribute('data-id-token'),
    logo: loginContainer.getAttribute('data-logo-url'),
    logoText: loginContainer.getAttribute('data-logo-text'),
    defaultPostAuthnRoutes: treatAsJSON(loginContainer.getAttribute('data-post-authn-routes')),
    iamCoreUrl: loginContainer.getAttribute('data-iam-core-url'),
    helpLink: loginContainer.getAttribute('data-help-link'),
    customLinks: loginContainer.getAttribute('data-custom-links'),
    customLinksAthena: treatAsJSON(loginContainer.getAttribute('data-custom-links-athena')),
    i18n: JSON.parse(loginContainer.getAttribute('data-i18n')),
    displayName: loginContainer.getAttribute('data-display-name'),
    language: loginContainer.getAttribute('data-language'),
    scope: loginContainer.getAttribute('data-scope'),
    userState: loginContainer.getAttribute('data-user-state'),
    sanitizeInfo: loginContainer.getAttribute('data-sanitize-info'),
    amplitudeApiKey: loginContainer.getAttribute('data-amplitude-api-key'),
    serverTime: loginContainer.getAttribute('data-server-time'),
    deviceTime: DEVICE_TIME,
    testApp: loginContainer.getAttribute('data-test-app'),
    workflow: loginContainer.getAttribute('data-workflow'),
    idp: loginContainer.getAttribute('data-idp'),
    features: treatAsJSON(loginContainer.getAttribute('data-features')),
    workflowType: loginContainer.getAttribute('data-workflow-type'),
    newUser: treatAsJSON(loginContainer.getAttribute('data-new-user')),
    lwaUniqId: loginContainer.getAttribute('data-lwa-uniq-id'),
    deviceId: loginContainer.getAttribute('data-device-id'),
    amplitudeUserId: loginContainer.getAttribute('data-amplitude-user-id'),
    amplitudeDeviceId: loginContainer.getAttribute('data-amplitude-device-id'),
    relogin: loginContainer.getAttribute('data-relogin'),
    workflowHandlerEndpoint: loginContainer.getAttribute('data-workflow-handler-endpoint'),
    aud: loginContainer.getAttribute('data-aud'),
    state: loginContainer.getAttribute('data-state'),
    emailToken: loginContainer.getAttribute('data-email-token'),
    hideResetAccountOption: treatAsJSON(loginContainer.getAttribute('data-hide-reset-account-option')),
    appClientId: loginContainer.getAttribute('data-app-clientid'),
    loginFrameUrl: loginContainer.getAttribute('data-iframe-url'),
    showTermsText: treatAsJSON(loginContainer.getAttribute('data-show-terms-text')),
    showTermsTextOnLogin: treatAsJSON(loginContainer.getAttribute('data-show-terms-text-on-login')),
    userAppTypeMismatchError: loginContainer.getAttribute('data-user-app-type-mismatch-error'),
    emailTokenLoggedInUserMismatchError: loginContainer.getAttribute('data-email-token-logged-in-user-mismatch-error'),
    userNamespace: treatAsJSON(loginContainer.getAttribute('data-user-namespace')),
    manageProfileError: treatAsJSON(loginContainer.getAttribute('data-manage-profile-error')),
    oktaOrgId: loginContainer.getAttribute('data-okta-org-id'),
    emailTokenUserRegisteredWarning: loginContainer.getAttribute('data-email-token-user-registered-warning'),
    showSetPasswordDescription: treatAsJSON(loginContainer.getAttribute('data-show-set-password-description')),
    skipPatientSummary: loginContainer.getAttribute('data-skip-patient-summary') === 'true',
    appType: loginContainer.getAttribute('data-app-type'),
    portalSettings: treatAsJSON(loginContainer.getAttribute('data-portal-settings')),
    hideRememberMe: loginContainer.getAttribute('data-hide-remember-me') === 'true',
    callProcessCreds: treatAsJSON(loginContainer.getAttribute('data-call-process-creds')),
  };

  return containerData;
}

export async function processCreds(creds, callback) {
  const requestData = {
    username: creds.username,
  };
  try {
    await iamCoreClient.processCredsApi(
      requestData
    );
  } catch (err) {
    logger.error(`Failed while making call to process-creds endpoint`, err);
  } finally {
    callback?.();
  }
}

export function createWidget(options) {
  const urlParams = new URLSearchParams(window.location.search);
  return new OktaSignIn({
    anetUserDomain: options.anetUserDomain,
    athenanet: options.athenanet,
    baseUrl: options.oktaUrl,
    consentOktaUrl: options.consentOktaUrl,
    supportEnv: options.supportEnv,
    username: options.username,
    applicationId: options.applicationId,
    redirectUri: options.redirectUri,
    stateToken: options.stateToken,
    helpLinks: {
      forgotPasswordAthena: options.forgotPasswordAthena,
      help: options.helpLink,
      custom: options.customLinks,
      customAthena: options.customLinksAthena,
    },
    language: options.language,
    logo: options.logo,
    logoText: options.logoText,
    iamCoreUrl: options.iamCoreUrl,
    createAccount: options.createAccount,
    defaultPostAuthnRoutes: options.defaultPostAuthnRoutes,
    passwordReset: urlParams.get("reset") === "1" ? true : false,
    processCreds: options.callProcessCreds ? processCreds : undefined,
    transformUsername: transformUsername.bind(undefined, options),
    i18n: options.i18n,
    displayName: options.displayName,
    scope: options.scope,
    userState: options.userState,
    sanitizeInfo: options.sanitizeInfo,
    amplitudeApiKey: options.amplitudeApiKey,
    testApp: options.testApp,
    workflow: options.workflow,
    idp: options.idp,
    features: options.features,
    workflowType: options.workflowType,
    newUser: options.newUser,
    lwaUniqId: options.lwaUniqId,
    proxiedAuthorizeUrl: options.proxiedAuthorizeUrl,
    proxiedAuthorizeValues: options.proxiedAuthorizeValues,
    amplitudeUserId: options.amplitudeUserId,
    amplitudeDeviceId: options.amplitudeDeviceId,
    deviceId: options.deviceId,
    relogin: options.relogin,
    workflowHandlerEndpoint: options.workflowHandlerEndpoint,
    aud: options.aud,
    state: options.state,
    emailToken: options.emailToken,
    hideResetAccountOption: options.hideResetAccountOption,
    appClientId: options.appClientId,
    showTermsText: options.showTermsText,
    showTermsTextOnLogin: options.showTermsTextOnLogin,
    userAppTypeMismatchError: options.userAppTypeMismatchError,
    emailTokenLoggedInUserMismatchError: options.emailTokenLoggedInUserMismatchError,
    manageProfileError: options.manageProfileError,
    oktaOrgId: options.oktaOrgId,
    showSetPasswordDescription: options.showSetPasswordDescription,
    emailTokenUserRegisteredWarning: options.emailTokenUserRegisteredWarning,
    skipPatientSummary: options.skipPatientSummary,
    appType: options.appType,
    portalSettings: options.portalSettings,
    hideRememberMe: options.hideRememberMe,
  });
}

export function renderLogin({ oktaSignIn, authorizeRedirectUrl, accessToken, forceLogin, oktaAuth }) {
  const containerData = getLoginContainerData();
  const deviceTimeOffset = getDeviceTimeOffset({
    serverTime: containerData.serverTime,
    deviceTime: containerData.deviceTime,
  });
  if (containerData.accessToken) {
    accessToken = formatAccessToken({ accessToken: containerData.accessToken, deviceTimeOffset });
    oktaSignIn.authClient.tokenManager.add('access_token', accessToken);
  }
  if (containerData.idToken) {
    oktaSignIn.authClient.tokenManager.add(
      'id',
      formatIdToken({ idToken: containerData.idToken, scopes: accessToken.scopes, deviceTimeOffset })
    );
  }
  // display the waiting route so in case there's a session, we don't flash the login
  // screen before going to the next post authn route.  Instead, we'll just see a spinner
  let initialPageLoaded = false;
  //Show spinner while AOSW renders
  if (containerData.accessToken) {
    window.location.hash = 'waiting';
  } else {
    document.getElementById(constants.LOGIN_CONTAINER_ID).style.display = 'none';
  }
  oktaSignIn.on('pageRendered', function () {
    if (forceLogin) {
      showPrimaryAuth();
    } else if (!initialPageLoaded) {
      oktaSignIn.authClient.session.exists()
        .then (async (exists) => {
          // Avoid redirecting to login_callback when session exists and an error has
          // occurred in pre/post-authn phase so that the error UI doesn't get removed
          if (isUserMismatchError(containerData)) {
            showPrimaryAuth();
          } else if (exists || containerData.accessToken) {
            // If an accessToken was passed in, it means we just successfully went through the auth code flow,
            // and there is definitely a session. If the session check declares that there is
            // no session, it means session checking is unreliable, and we should mark it as
            // such so that all subsequent session checking will be disabled
            if (!exists) {
              oktaSignIn.router.settings.set('sessionCheckingUnreliable', true);
              oktaSignIn.router.appState.trigger('beginPostAuthnRoutes');
            } else if (!containerData.accessToken && !accessToken) {
              // We have a session, but no access token. Send user to endpoint which will redirect to okta /authorize
              authorizeToOkta(containerData.proxiedAuthorizeValues, oktaAuth);
            } else {
              oktaSignIn.router.appState.trigger('beginPostAuthnRoutes');
              const { oktaUrl, loginFrameUrl } = containerData;
              if (loginFrameUrl) {
                await loadAndSyncSession({ loginFrameUrl, oktaUrl });
              }
            }
          } else if (containerData.idp) {
            authorizeToOkta(containerData.proxiedAuthorizeValues, oktaAuth);
          } else {
            showPrimaryAuth();
          }
        });
      initialPageLoaded = true;
    }

    const userElements = document.querySelectorAll('[name="username"]');

    userElements.forEach(userElement => {
      // Per https://stackoverflow.com/questions/35513968/disable-auto-correct-in-safari-text-input we
      // need to set all the following attributes to avoid having autocorrect on the username field
      // in Safari. (Okta already sets autocomplete="off")
      userElement.setAttribute('autocorrect', 'off');
      userElement.setAttribute('autocapitalize', 'off');
      userElement.setAttribute('spellcheck', false);
    });

  });
  oktaSignIn.renderEl(
    { el: LOGIN_CONTAINER_SELECTOR },
    (res) => {
      if (res.status === 'SUCCESS' && res.session.token) {
        const proxiedAuthorizeValues = containerData.proxiedAuthorizeValues;
        // Go get the access token by initiating an OIDC flow via our endpoint that redirects to okta /authorize
        proxiedAuthorizeValues.sessionToken = res.session.token;
        authorizeToOkta(containerData.proxiedAuthorizeValues, oktaAuth);
      } else {
        window.location = `${authorizeRedirectUrl}`;
      }
    },
    (err) => {
      // Temporary error handling
      // eslint-disable-next-line no-alert
      alert(`error: ${JSON.stringify(err)}`);
    }
  );
}

